import { IBaseVideoDownloadTrack, IVideoDownloadTrack } from '@th-common/interfaces/video/video-request';
import { IVideoRequestBaseState } from '@th-common/interfaces/video/video-request-base-state';
import dayjs, { Dayjs } from 'dayjs';

export namespace VideoDownloadUtils {
  export const getVideoRequestState = (start: string, end: string): IVideoRequestBaseState => {
    const videoRequestStartDayjs = dayjs.parseZone(start);
    const videoRequestEndDayjs = dayjs.parseZone(end);
    const videoRequestMin = 0;
    const videoRequestMax = videoRequestEndDayjs.diff(videoRequestStartDayjs, 'ms');

    return {
      videoRequestStartDayjs,
      videoRequestEndDayjs,
      videoRequestMin,
      videoRequestMax,
    };
  };

  export const parseVideoDownloadTrackItem = (
    track: IBaseVideoDownloadTrack,
    videoRequestStartDayjs: Dayjs,
  ): IVideoDownloadTrack => {
    const trackStartDayjs = dayjs.parseZone(track.start);
    const trackEndDayjs = dayjs.parseZone(track.end);

    const startTimelineValue = trackStartDayjs.diff(videoRequestStartDayjs, 'ms');
    const endTimelineValue = trackEndDayjs.diff(videoRequestStartDayjs, 'ms');

    return {
      id: track.id,
      start: track.start,
      end: track.end,
      severity: track.severity,
      startTimelineValue,
      endTimelineValue,
    };
  };

  export const parseVideoDownloadTracks = (
    tracks: IBaseVideoDownloadTrack[],
    videoRequestStartDayjs: Dayjs,
  ): IVideoDownloadTrack[] => {
    return tracks.map((track) => parseVideoDownloadTrackItem(track, videoRequestStartDayjs));
  };
}
