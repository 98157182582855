import { TPlaybackSpeedSettingsValue } from '@th-common/enums/video-playback/playback-speed.enum';
import { TViewLayout } from '@th-common/interfaces/player/player';
import {
  IAudioSourceInfo, IVideoBlurring, IVideoSourceInfo,
} from '@th-common/interfaces/player/video-source-info';
import { ICameraHlsData, IVideoDownloadTrack, IVideoHlsTrack } from '@th-common/interfaces/video/video-request';
import { IVideoRequestBaseState } from '@th-common/interfaces/video/video-request-base-state';

export enum TPlayerMode {
  Download = 'download',
  Offline = 'offline',
  Online = 'online',
  File = 'file',
  None = '',
}

export interface IVideoPlaybackState extends IVideoRequestBaseState {
  playerMode: TPlayerMode;
  docToken: string;
  dvrConnectionAddress: string;
  deviceName: string;
  fileName: string;
  serialNumber: string;
  videoDownloadTracks: IVideoDownloadTrack[];
  videoDownloadTrackIndex: number | null;
  tracks: IVideoHlsTrack[];
  cameraHlsDataByTrack: Record<number, ICameraHlsData>[];
  currentTrackIndex: number | null;
  cameras: IVideoSourceInfo[];
  audioSources: IAudioSourceInfo[];
  dirHandle: FileSystemDirectoryHandle | null;
  //
  playerCurrentTimeRelated: number;
  currentTimelineValue: number;
  masterPlayerNumber: number;
  clipValue: [number, number];
  clipDateTime: [string, string];
  blurring: IVideoBlurring | null;
  viewLayout: TViewLayout;
  isMetaDataPanelOpen: boolean;
  // Player
  showClip: boolean;
  playingForward: boolean;
  playingBackward: boolean;
  playbackSpeed: TPlaybackSpeedSettingsValue;
  bigCameraNumber: number | null;
  cameraNumbersCanPlay: number[];
  cameraNumbersBuffering: number[];
  removedBlurringOnCameras: number[];
  // Audio
  audioMuted: boolean;
}
