
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dvrApi } from '@th-common/api/dvr.api';
import { TPlaybackSpeedSettingsValue } from '@th-common/enums/video-playback/playback-speed.enum';
import { isAvSourceAudio, isAvSourceCamera, TAvSourceType } from '@th-common/enums/video-player/av-source-type.enum';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IAudioSourceInfo, IVideoAdjustments } from '@th-common/interfaces/player/video-source-info';
import {
  IBaseVideoDownloadTrack,
  ICameraHlsData, IVideoAvSource, IVideoDownloadTrack, IVideoFile, IVideoHlsTrack,
  IVideoTrack,
} from '@th-common/interfaces/video/video-request';
import { StorageUtils } from '@th-common/utils/storage';
import { VideoDownloadUtils } from '@th-common/utils/video-player/video-download-utils';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { VideoPlaybackUtils } from './utils/video-player-utils';
import { VideoTrackUtils } from './utils/video-track-utils';
import { startTime } from './gps-fake-data';
import { IVideoPlaybackState, TPlayerMode } from './video-playback-state.interface';

dayjs.extend(isBetween);

const initialState: IVideoPlaybackState = {
  playerMode: TPlayerMode.None,
  docToken: '',
  dvrConnectionAddress: '',
  deviceName: '',
  fileName: '',
  serialNumber: '',
  videoDownloadTracks: [],
  videoDownloadTrackIndex: null,
  tracks: [],
  currentTrackIndex: 0,
  cameraHlsDataByTrack: [],
  cameras: [],
  audioSources: [],
  dirHandle: null,
  blurring: null,
  playerCurrentTimeRelated: 0,
  currentTimelineValue: 0,
  masterPlayerNumber: -1,
  clipValue: [0, 0],
  clipDateTime: ['', ''],
  videoRequestStartDayjs: dayjs(),
  videoRequestEndDayjs: dayjs(),
  videoRequestMin: 0,
  videoRequestMax: 0,
  viewLayout: TViewLayout.Grid,
  isMetaDataPanelOpen: false,
  // Player
  showClip: false,
  playingForward: false,
  playingBackward: false,
  playbackSpeed: TPlaybackSpeedSettingsValue.Speed1,
  bigCameraNumber: null,
  cameraNumbersCanPlay: [],
  cameraNumbersBuffering: [],
  removedBlurringOnCameras: [],
  // Audio
  audioMuted: false,
};

export const slice = createSlice({
  name: 'videoPlayback',
  initialState,
  reducers: {
    setDvrConnectionAddress: (state, { payload }: { payload: string }) => {
      state.dvrConnectionAddress = payload;
    },
    setDocToken: (state, { payload }: { payload: string }) => {
      state.docToken = payload;
    },
    setDeviceName: (state, { payload }: { payload: string }) => {
      state.deviceName = payload;
    },
    setFileName: (state, { payload }: { payload: string }) => {
      state.fileName = payload;
    },
    setPlayerMode: (state, { payload }: { payload: TPlayerMode }) => {
      state.playerMode = payload;
    },
    onTimelineUpdate: (state, { payload }: { payload: number }) => {
      if (!VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode)) {
        const trackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
          payload,
          state.tracks,
          state.currentTrackIndex,
        );

        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTrackIndex: trackIndex,
            currentTimelineValue: payload,
          },
        );
        Object.assign(state, stateChanges);
      } else {
        const videoDownloadTrackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
          payload,
          state.videoDownloadTracks,
          state.videoDownloadTrackIndex,
        );

        state.videoDownloadTrackIndex = videoDownloadTrackIndex;
        state.currentTimelineValue = payload;
      }
    },
    // Video element (player) current time uses seconds with up to 6 decimal places
    onPlayerCurrentTimeUpdate: (state, { payload }: { payload: number }) => {
      if (state.videoDownloadTrackIndex === null || state.currentTrackIndex === null || state.masterPlayerNumber === -1) {
        return;
      }

      const playerTimeAsTimelineValue = VideoPlaybackUtils.getTimelineValueByPlayerTime(
        VideoPlaybackUtils.playerTimeToTimelineValue(payload),
        state.tracks[state.currentTrackIndex].startTimelineValue,
      );

      const cameraNumbersCanPlayStateChanges = VideoPlaybackUtils.updateTimelineValue({
        ...state,
        currentTimelineValue: playerTimeAsTimelineValue,
      });
      Object.assign(state, cameraNumbersCanPlayStateChanges);

      const currentTrackStartTimelineValue = state.tracks[state.currentTrackIndex].minPlayerStartTimelineValue;

      // Switch to the previous track if reached the min player start of the current track or stop playing backward
      if (state.playingBackward && state.currentTimelineValue === currentTrackStartTimelineValue) {
        VideoTrackUtils.switchToPreviousTrackOrStopPlaying(state);
        return;
      }

      const currentTrackEndTimelineValue = state.tracks[state.currentTrackIndex].maxPlayerEndTimelineValue;

      // Switch to the next track if reached the min player end of the current track or stop playing forward
      if (state.playingForward && state.currentTimelineValue === currentTrackEndTimelineValue) {
        VideoTrackUtils.switchToNextTrackOrStopPlaying(state);
        return;
      }

      const reachedMasterCameraEndWhilePlayingForward = state.playingForward
        && state.currentTimelineValue === state.cameraHlsDataByTrack[state.currentTrackIndex][state.masterPlayerNumber].endTimelineValue;

      const reachedMasterCameraStartWhilePlayingBackward = state.playingBackward
        && state.currentTimelineValue === state.cameraHlsDataByTrack[state.currentTrackIndex][state.masterPlayerNumber].startTimelineValue;

      if (reachedMasterCameraEndWhilePlayingForward || reachedMasterCameraStartWhilePlayingBackward) {
        const newMasterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
        state.masterPlayerNumber = newMasterPlayerNumber;
      }
    },
    syncPlayerCurrentTimeToTimeline: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null) {
        return;
      }
      const timelineRelatedToTrack = state.currentTimelineValue - state.tracks[currentTrackIndex].startTimelineValue;

      const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(timelineRelatedToTrack) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    stepFrameForward: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null || state.masterPlayerNumber === -1) {
        return;
      }

      const masterPlayerNumberFps = state.cameraHlsDataByTrack[currentTrackIndex][state.masterPlayerNumber].fps;
      const timelineValue = VideoPlaybackUtils.roundTimelineValue(state.currentTimelineValue + (1000 / masterPlayerNumberFps));

      const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(
        timelineValue - state.tracks[currentTrackIndex].startTimelineValue,
      ) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    stepFrameBackward: (state) => {
      const currentTrackIndex = state.currentTrackIndex;

      if (currentTrackIndex === null || state.masterPlayerNumber === -1) {
        return;
      }

      const masterPlayerNumberFps = state.cameraHlsDataByTrack[currentTrackIndex][state.masterPlayerNumber].fps;
      const timelineValue = state.currentTimelineValue - (1000 / masterPlayerNumberFps);

      const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(
        timelineValue - state.tracks[currentTrackIndex].startTimelineValue,
      ) / 1000;
      state.playerCurrentTimeRelated = +playerCurrentTimeRelated.toFixed(6);
    },
    masterPlayerEnded: (state) => {
      const newMasterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
      if (state.masterPlayerNumber === newMasterPlayerNumber) {
        VideoTrackUtils.switchToNextTrackOrStopPlaying(state);
      } else {
        state.masterPlayerNumber = newMasterPlayerNumber;
      }
    },
    setCurrentDateTime: (state, { payload }: { payload: Dayjs }) => {
      if (!VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode)) {
        const trackIndex = VideoPlaybackUtils.getTrackIndexByDay(
          payload,
          state.tracks,
        );

        const currentTrackMinPlayerStartTimelineValue = state.tracks[trackIndex].minPlayerStartTimelineValue;
        const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
          {
            ...state,
            currentTrackIndex: trackIndex,
            currentTimelineValue: currentTrackMinPlayerStartTimelineValue,
          },
        );
        Object.assign(state, stateChanges);
      } else {
        const videoDownloadTrackIndex = VideoPlaybackUtils.getTrackIndexByDay(
          payload,
          state.videoDownloadTracks,
        );

        const downloadTrackStartTimelineValue = state.videoDownloadTracks[videoDownloadTrackIndex].startTimelineValue;
        state.currentTimelineValue = downloadTrackStartTimelineValue;
        state.videoDownloadTrackIndex = videoDownloadTrackIndex;
      }
    },
    setClipValue: (state, { payload }: { payload: [number, number] }) => {
      state.clipValue = payload;
    },
    cameraSourcesVisibility: (state, { payload }: { payload: string[] }) => {
      state.cameras = state.cameras.map((camera) => {
        return {
          ...camera,
          isPlayerHide: !payload.includes(camera.name),
        };
      });

      state.masterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
    },
    audioSourcesVisibility: (state, { payload }: { payload: string[] }) => {
      state.audioSources = state.audioSources.map((audio) => {
        return {
          ...audio,
          isPlayerHide: !payload.includes(audio.name),
        };
      });
    },
    hidePlayer: (state, { payload }: { payload: number }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.number === payload) {
          return {
            ...camera,
            isPlayerHide: true,
          };
        }
        return camera;
      });

      state.masterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
    },
    showPlayer: (state, { payload }: { payload: string }) => {
      state.cameras = state.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: false,
          };
        }
        return camera;
      });
    },
    setViewLayout: (state, { payload }: { payload: TViewLayout }) => {
      state.viewLayout = payload;

      if (payload === TViewLayout.List) {
        state.bigCameraNumber = null;
      }
    },
    cameraNumberBuffering: (state, { payload }: { payload: number }) => {
      if (state.cameraNumbersBuffering.includes(payload)) {
        return;
      }

      state.cameraNumbersBuffering = [...state.cameraNumbersBuffering, payload];
      state.masterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
    },
    cameraNumberBuffered: (state, { payload }: { payload: number }) => {
      const updatedCameraNumbersBuffering = state.cameraNumbersBuffering.filter((cameraNumber) => cameraNumber !== payload);
      if (updatedCameraNumbersBuffering.length === state.cameraNumbersBuffering.length) {
        return;
      }

      state.cameraNumbersBuffering = updatedCameraNumbersBuffering;
      state.masterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
    },
    toggleMetaDataPanel: (state, { payload }: { payload: boolean }) => {
      state.isMetaDataPanelOpen = payload;
    },
    setVideoAdjustments: (state, { payload }: { payload: { cameraNumber: number; videoParams: IVideoAdjustments } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        cameraInfo.brightness = payload.videoParams.brightness;
        cameraInfo.contrast = payload.videoParams.contrast;
        cameraInfo.saturate = payload.videoParams.saturate;
      }
    },
    setVideoSource: (state, { payload }: { payload: { file: File; durationInSeconds: number } }) => {
      const {
        videoRequestStartDayjs,
        videoRequestEndDayjs,
        videoRequestMin,
        videoRequestMax,
      } = VideoDownloadUtils.getVideoRequestState(startTime.toISOString(), startTime.add(payload.durationInSeconds, 'second').toISOString());

      const cameraAvSources: IVideoAvSource[] = [
        {
          name: 'File Source',
          type: TAvSourceType.IPCamera,
          number: 1,
        },
      ];
      state.currentTimelineValue = 0;
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
      state.deviceName = 'File Source';
      state.serialNumber = '';
      state.cameras = cameraAvSources.map((source) => {
        return {
          name: source.name,
          number: source.number,
          isPlayerHide: false,
          playerType: source.type,
          isVideoAdjustmentsOpen: false,
          brightness: 1,
          contrast: 1,
          saturate: 1,
        };
      });
      const downloadTrack = VideoDownloadUtils.parseVideoDownloadTrackItem(
        {
          id: 1,
          start: startTime.toISOString(),
          end: startTime.add(payload.durationInSeconds, 'second').toISOString(),
          severity: 1,
        },
        videoRequestStartDayjs,
      );
      const tracks = [
        {
          ...downloadTrack,
          minPlayerStartTimelineValue: videoRequestMin,
          maxPlayerEndTimelineValue: videoRequestMax,
        },
      ];
      state.tracks = tracks;
      const cameraHlsDataByTrack: Record<number, ICameraHlsData>[] = [
        {
          [1]: {
            number: 1,
            src: URL.createObjectURL(payload.file),
            fps: 15,
            startTimelineValue: videoRequestMin,
            endTimelineValue: videoRequestMax,
            playerCurrentTimeStartRelatedToTrack: videoRequestMin,
            playerType: TAvSourceType.IPCamera,
          },
        },
      ];
      state.cameraHlsDataByTrack = cameraHlsDataByTrack;
      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestEndDayjs = videoRequestEndDayjs;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: 0,
        currentTimelineValue: 0,
        cameraHlsDataByTrack,
        tracks,
      });
      Object.assign(state, stateChanges);
    },
    resetVideoAdjustments: (state, { payload }: { payload: { cameraNumber: number } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        cameraInfo.brightness = 1;
        cameraInfo.contrast = 1;
        cameraInfo.saturate = 1;
      }
    },
    toggleVideoAdjustmentsPanel: (state, { payload }: { payload: { cameraNumber: number; isOpen?: boolean } }) => {
      const cameraInfo = state.cameras.find((camera) => camera.number === payload.cameraNumber);
      if (cameraInfo) {
        if (payload.isOpen !== undefined) {
          cameraInfo.isVideoAdjustmentsOpen = payload.isOpen;
        } else {
          cameraInfo.isVideoAdjustmentsOpen = !cameraInfo.isVideoAdjustmentsOpen;
        }
      }
    },
    // Player
    toggleClip: (state) => {
      state.showClip = !state.showClip;
    },
    playingForward: (state) => {
      state.playingForward = true;
      state.playingBackward = false;
    },
    playingBackward: (state) => {
      state.playingForward = false;
      state.playingBackward = true;
    },
    pauseVideo: (state) => {
      state.playingForward = false;
      state.playingBackward = false;
    },
    setPlaybackSpeed: (state, { payload }: { payload: TPlaybackSpeedSettingsValue }) => {
      state.playbackSpeed = payload;
    },
    skipSegmentToEnd: (state) => {
      if (!VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode)) {
        let trackIndex: number;

        const playerCurrentTimeRelatedToTrack = state.currentTrackIndex === null ? null : VideoPlaybackUtils.playerTimeToTimelineValue(
          state.playerCurrentTimeRelated,
        ) + state.tracks[state.currentTrackIndex].startTimelineValue;

        const reachedEndOfPlayer = state.currentTimelineValue !== 0
        && state.currentTimelineValue === playerCurrentTimeRelatedToTrack;

        if (state.currentTrackIndex !== null && reachedEndOfPlayer) {
          trackIndex = state.currentTrackIndex < state.tracks.length - 1 ? state.currentTrackIndex + 1 : state.tracks.length - 1;
        } else {
          trackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
            state.currentTimelineValue,
            state.tracks,
            state.currentTrackIndex,
          );
        }

        if (trackIndex !== -1) {
          const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
            {
              ...state,
              currentTrackIndex: trackIndex,
              currentTimelineValue: state.tracks[trackIndex].endTimelineValue,
            },
          );
          Object.assign(state, stateChanges);
        }
      } else {
        let videoDownloadTrackIndex;

        const playerCurrentTimeRelatedToTrack = state.videoDownloadTrackIndex === null ? null : VideoPlaybackUtils.playerTimeToTimelineValue(
          state.playerCurrentTimeRelated,
        ) + state.videoDownloadTracks[state.videoDownloadTrackIndex].startTimelineValue;

        const reachedEndOfPlayer = state.currentTimelineValue !== 0
        && state.currentTimelineValue === playerCurrentTimeRelatedToTrack;

        if (state.videoDownloadTrackIndex !== null && reachedEndOfPlayer) {
          videoDownloadTrackIndex = state.videoDownloadTrackIndex < state.videoDownloadTracks.length - 1
            ? state.videoDownloadTrackIndex + 1 : state.videoDownloadTracks.length - 1;
        } else {
          videoDownloadTrackIndex = VideoPlaybackUtils.getTrackIndexByTimelineValue(
            state.currentTimelineValue,
            state.videoDownloadTracks,
            state.videoDownloadTrackIndex,
          );
        }

        if (videoDownloadTrackIndex !== -1) {
          state.videoDownloadTrackIndex = videoDownloadTrackIndex;
        }
        state.currentTimelineValue = VideoPlaybackUtils.roundTimelineValue(
          state.videoDownloadTracks[videoDownloadTrackIndex].endTimelineValue,
        );
      }
    },
    skipSegmentToStart: (state) => {
      if (!VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode)) {
        let trackIndex = state.tracks.length - 1;

        for (let i = state.tracks.length - 1; i >= 0; i--) {
          if (state.currentTimelineValue > state.tracks[i].startTimelineValue) {
            trackIndex = i;
            break;
          }
        }
        if (trackIndex !== -1) {
          const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange(
            {
              ...state,
              currentTrackIndex: trackIndex,
              currentTimelineValue: state.tracks[trackIndex].startTimelineValue,
            },
          );
          Object.assign(state, stateChanges);
        }
      } else {
        let videoDownloadTrackIndex = state.videoDownloadTracks.length - 1;

        for (let i = state.videoDownloadTracks.length - 1; i >= 0; i--) {
          if (state.currentTimelineValue > state.videoDownloadTracks[i].startTimelineValue) {
            videoDownloadTrackIndex = i;
            break;
          }
        }
        if (videoDownloadTrackIndex !== -1) {
          state.videoDownloadTrackIndex = videoDownloadTrackIndex;
        }
        state.currentTimelineValue = state.videoDownloadTracks[videoDownloadTrackIndex].startTimelineValue;
      }
    },
    setBigCameraNumber: (state, { payload }: { payload: number | null }) => {
      let bufferingCameraNumbers: number[] = [];
      if (payload !== null && !state.cameraNumbersBuffering.includes(payload)) {
        bufferingCameraNumbers = [...state.cameraNumbersBuffering, payload];
      }

      if (state.bigCameraNumber !== null && !state.cameraNumbersBuffering.includes(state.bigCameraNumber)) {
        bufferingCameraNumbers = [...bufferingCameraNumbers, state.bigCameraNumber];
      }

      if (bufferingCameraNumbers.length > 0) {
        state.cameraNumbersBuffering = bufferingCameraNumbers;
        state.masterPlayerNumber = VideoPlaybackUtils.getMasterPlayerNumber(state);
      }

      state.bigCameraNumber = state.bigCameraNumber === payload ? null : payload;
    },
    // API
    setVideoDownloadTracks: (state, { payload }: { payload: IBaseVideoDownloadTrack[] }) => {
      const {
        videoRequestStartDayjs,
        videoRequestEndDayjs,
        videoRequestMin,
        videoRequestMax,
      } = VideoDownloadUtils.getVideoRequestState(payload[0].start, payload[payload.length - 1].end);

      const videoDownloadTracks = VideoDownloadUtils.parseVideoDownloadTracks(payload, videoRequestStartDayjs);

      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestEndDayjs = videoRequestEndDayjs;
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
      state.videoDownloadTracks = videoDownloadTracks;

      if (!VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode)) {
        state.videoDownloadTrackIndex = 0;
      }
    },
    setVideoPlayerData: (state, { payload }: { payload: IVideoFile }) => {
      if (state.videoDownloadTrackIndex === null) {
        return;
      }

      const cameraAvSources = payload.avSources.filter(
        (source) => isAvSourceCamera(source.type),
      );
      const avSourceNumbers = payload.avSources.map((source) => source.number);

      const apiBaseUrl = (StorageUtils.search('env', 'sessionStorage') as { apiBaseUrl: string }).apiBaseUrl;

      const videoDownload = state.videoDownloadTracks[state.videoDownloadTrackIndex];

      state.deviceName = payload.device;
      state.serialNumber = payload.serialNumber;
      state.cameras = cameraAvSources.map((source) => {
        return {
          name: source.name,
          number: source.number,
          isPlayerHide: false,
          playerType: source.type,
          isVideoAdjustmentsOpen: false,
          brightness: 1,
          contrast: 1,
          saturate: 1,
        };
      });
      state.audioSources = payload.avSources
        .filter(source => isAvSourceAudio(source.type))
        .map((source): IAudioSourceInfo => {
          return {
            name: source.name,
            number: source.number,
            isPlayerHide: true,
          };
        });
      const {
        tracks,
        cameraHlsDataByTrack,
      } = payload.tracks.reduce((acc, track: IVideoTrack) => {
        const downloadTrack: IVideoDownloadTrack = VideoDownloadUtils.parseVideoDownloadTrackItem({
          ...track,
          id: -1,
        }, state.videoRequestStartDayjs);

        const trackCameraSources = track.avSources.filter(avSource => avSourceNumbers.includes(avSource.avSource));

        let minPlayerStartTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, trackCameraSources[0].start);
        let maxPlayerEndTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, trackCameraSources[0].end);

        acc.cameraHlsDataByTrack.push(trackCameraSources.reduce((hlsDataByTrack, avSource) => {
          const sourceType = payload.avSources.find(source => source.number === avSource.avSource)?.type || null;

          const avSourceStart = isAvSourceCamera(sourceType) ? avSource.start : track.start;
          const avSourceEnd = isAvSourceCamera(sourceType) ? avSource.end : track.end;

          const hlsStartTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, avSourceStart);
          const hlsEndTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, avSourceEnd);
          const playerCurrentTimeStartRelatedToTrack = VideoPlaybackUtils.roundPlayerCurrentTime(
            (hlsStartTimelineValue - downloadTrack.startTimelineValue) / 1000,
          );

          hlsDataByTrack[avSource.avSource] = {
            number: avSource.avSource,
            src: `${apiBaseUrl}/video-download/${videoDownload.id}/${avSource.playlist}`,
            fps: avSource.fps,
            startTimelineValue: hlsStartTimelineValue,
            endTimelineValue: hlsEndTimelineValue,
            playerCurrentTimeStartRelatedToTrack,
            playerType: sourceType,
          };

          if (hlsStartTimelineValue < minPlayerStartTimelineValue) {
            minPlayerStartTimelineValue = hlsStartTimelineValue;
          }

          if (hlsEndTimelineValue > maxPlayerEndTimelineValue) {
            maxPlayerEndTimelineValue = hlsEndTimelineValue;
          }

          return hlsDataByTrack;
        }, {} as Record<number, ICameraHlsData>));

        acc.tracks.push({
          ...downloadTrack,
          minPlayerStartTimelineValue,
          maxPlayerEndTimelineValue,
        });

        return acc;
      }, {
        tracks: [] as IVideoHlsTrack[],
        cameraHlsDataByTrack: [] as Record<number, ICameraHlsData>[],
      });

      state.tracks = tracks;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: 0,
        currentTimelineValue: videoDownload.startTimelineValue,
        cameraHlsDataByTrack,
        tracks,
      });
      Object.assign(state, stateChanges);
      state.cameraHlsDataByTrack = cameraHlsDataByTrack;
      state.blurring = payload.blurring;
      state.removedBlurringOnCameras = [];
    },
    removedBlurringOnCameras: (state, { payload }: { payload: number }) => {
      state.removedBlurringOnCameras = [...state.removedBlurringOnCameras, payload];
    },
    resetBlurringOnCameras: (state, { payload }: { payload: number }) => {
      state.removedBlurringOnCameras = state.removedBlurringOnCameras.filter(
        (camera) => camera !== payload,
      );
    },

    // Audio
    toggleAudioMute: (state) => {
      state.audioMuted = !state.audioMuted;
    },
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackState>) => {
    builder.addMatcher(dvrApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
      if (state.videoDownloadTrackIndex === null) {
        return;
      }

      const cameraAvSources = payload.avSources.filter((source) => isAvSourceCamera(source.type));
      const cameraAvSourceNumbers = cameraAvSources.map((source) => source.number);
      const apiBaseUrl = (StorageUtils.search('env', 'sessionStorage') as { apiBaseUrl: string }).apiBaseUrl;
      const dvrBaseUrl = `${state.dvrConnectionAddress}/api`;

      const hlsBaseUrl = !VideoPlaybackUtils.isDeviceConnectionPlayerMode(state.playerMode) ? apiBaseUrl : dvrBaseUrl;

      const videoDownload = state.videoDownloadTracks[state.videoDownloadTrackIndex];

      state.deviceName = payload.device;
      state.serialNumber = payload.serialNumber;
      state.cameras = cameraAvSources.map((source) => {
        return {
          name: source.name,
          number: source.number,
          isPlayerHide: false,
          playerType: source.type,
          isVideoAdjustmentsOpen: false,
          brightness: 1,
          contrast: 1,
          saturate: 1,
        };
      });
      state.audioSources = payload.avSources
        .filter(source => isAvSourceAudio(source.type))
        .map((source): IAudioSourceInfo => {
          return {
            name: source.name,
            number: source.number,
            isPlayerHide: true,
          };
        });
      const {
        tracks,
        cameraHlsDataByTrack,
      } = payload.tracks.reduce((acc, track: IVideoTrack) => {
        const downloadTrack: IVideoDownloadTrack = VideoDownloadUtils.parseVideoDownloadTrackItem({
          ...track,
          id: -1,
        }, state.videoRequestStartDayjs);

        const trackCameraSources = track.avSources.filter(avSource => cameraAvSourceNumbers.includes(avSource.avSource));

        let minPlayerStartTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, trackCameraSources[0].start);
        let maxPlayerEndTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, trackCameraSources[0].end);

        acc.cameraHlsDataByTrack.push(trackCameraSources.reduce((hlsDataByTrack, avSource, index) => {
          const hlsStartTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, avSource.start);
          const hlsEndTimelineValue = VideoPlaybackUtils.dateToTimelineValue(state.videoRequestStartDayjs, avSource.end);
          const playerCurrentTimeStartRelatedToTrack = VideoPlaybackUtils.roundPlayerCurrentTime(
            (hlsStartTimelineValue - downloadTrack.startTimelineValue) / 1000,
          );

          hlsDataByTrack[avSource.avSource] = {
            number: avSource.avSource,
            src: `${hlsBaseUrl}/video-download/${videoDownload.id}/${avSource.playlist}`,
            fps: avSource.fps,
            startTimelineValue: hlsStartTimelineValue,
            endTimelineValue: hlsEndTimelineValue,
            playerCurrentTimeStartRelatedToTrack,
            playerType: cameraAvSources[index].type,
          };

          if (hlsStartTimelineValue < minPlayerStartTimelineValue) {
            minPlayerStartTimelineValue = hlsStartTimelineValue;
          }

          if (hlsEndTimelineValue > maxPlayerEndTimelineValue) {
            maxPlayerEndTimelineValue = hlsEndTimelineValue;
          }

          return hlsDataByTrack;
        }, {} as Record<number, ICameraHlsData>));

        acc.tracks.push({
          ...downloadTrack,
          minPlayerStartTimelineValue,
          maxPlayerEndTimelineValue,
        });

        return acc;
      }, {
        tracks: [] as IVideoHlsTrack[],
        cameraHlsDataByTrack: [] as Record<number, ICameraHlsData>[],
      });

      state.tracks = tracks;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: 0,
        currentTimelineValue: state.currentTimelineValue >= videoDownload.startTimelineValue
          ? state.currentTimelineValue : videoDownload.startTimelineValue,
        cameraHlsDataByTrack,
        tracks,
      });
      Object.assign(state, stateChanges);
      state.cameraHlsDataByTrack = cameraHlsDataByTrack;

      state.blurring = payload.blurring;
      state.removedBlurringOnCameras = [];
    });
  },
});

export default slice.reducer;
