import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { reduxTags } from '@th-common/enums/redux-tags.enum';

export const dvrApiRoot = createApi({
  reducerPath: 'dvrApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  tagTypes: reduxTags,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
