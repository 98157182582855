import {
  BaseQueryApi, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryReturnValue,
} from '@reduxjs/toolkit/query';
import { IVideoAvailData } from '@th-common/interfaces/video/avail-data';
import { IVideoEvent } from '@th-common/interfaces/video/event-data';
import { IVideoInitData } from '@th-common/interfaces/video/init-data';
import { IJ1939 } from '@th-common/interfaces/video/j1939-data';
import { IVideoFile } from '@th-common/interfaces/video/video-request';
import { apiRoot } from '@th-common/store/api';
import { IBaseQueryExtraOptions } from '@th-common/store/handlers';

interface IVideoDownloadDataParams {
  videoDownloadId: number | string;
  docToken: string;
  fileNames: string[];
}

enum TVideoDownloadType {
  GPS = 'gps',
  EVENT = 'event',
  AVAIL = 'avail',
  INIT = 'init',
  CLEVER = 'clever',
  EQUANS = 'equans',
  J1939 = 'j1939',
}

const mapFileNamesToAPI = (
  params: IVideoDownloadDataParams,
  type: TVideoDownloadType,
): string[] => {
  return params.fileNames
    .map(
      (fileName) => `video-download/${params.videoDownloadId}/${type}/${fileName}?docToken=${params.docToken}`,
    );
};

const buildQueryFnForFileNames = <T>(
  type: TVideoDownloadType,
): ((
  _arg: IVideoDownloadDataParams,
  _queryApi: BaseQueryApi,
  _extraOptions: IBaseQueryExtraOptions,
  fetchWithBQ: (arg: string | FetchArgs) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
) => Promise<{ data: T } | { error: FetchBaseQueryError }>) => {
  return async (
    _arg: IVideoDownloadDataParams,
    _queryApi: BaseQueryApi,
    _extraOptions: IBaseQueryExtraOptions,
    fetchWithBQ: (arg: string | FetchArgs) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
  ) => {
    const results = await Promise.all(
      mapFileNamesToAPI(_arg, type).map((fileApiUrl) => fetchWithBQ(fileApiUrl)),
    );

    if (results) {
      const flattenedData = results.map((res) => res.data).flat();

      return { data: flattenedData as T };
    }
    return {
      error: {
        status: 'CUSTOM_ERROR',
        error: 'No data',
      } as FetchBaseQueryError,
    };
  };
};

export const videoDownloadApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getToken: builder.query<{
      token: string;
      expiresIn: number;
    }, number>({
      query: (videoDownloadId) => `video-download/${videoDownloadId}/token`,
      extraOptions: {
        skipErrorNotification: true,
      },
    }),
    getVideoDownload: builder.query<IVideoFile, { videoDownloadId: number | string; docToken: string }>({
      query: ({
        videoDownloadId,
        docToken,
      }) => {
        return {
          url: `video-download/${videoDownloadId}/index.json`,
          method: 'GET',
          params: {
            docToken,
          },
        };
      },
      extraOptions: {
        skipErrorNotification: true,
      },
    }),
    getVideoDownloadTrackGpsData: builder.query<string[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<string[]>(TVideoDownloadType.GPS),
    }),
    getVideoDownloadEventsData: builder.query<IVideoEvent[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<IVideoEvent[]>(TVideoDownloadType.EVENT),
    }),
    getVideoDownloadAvailData: builder.query<IVideoAvailData[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<IVideoAvailData[]>(TVideoDownloadType.AVAIL),
    }),
    getVideoDownloadInitData: builder.query<IVideoInitData[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<IVideoInitData[]>(TVideoDownloadType.INIT),
    }),
    getVideoDownloadJ1939Data: builder.query<IJ1939[], IVideoDownloadDataParams>({
      queryFn: buildQueryFnForFileNames<IJ1939[]>(TVideoDownloadType.J1939),
    }),
    videoDownloadValidateBlurPassword: builder.mutation<boolean, { password: string; storedEncryptedPassword: string }>({
      query: (data) => ({
        url: 'video-download/validate-blur-password',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
