import { IDvrInfo } from '@th-common/interfaces/dvr/dvr-info.interface';
import {
  IBaseVideoDownloadTrack, IDvrVideoFile, IDvrVideoTrack, IVideoFile,
} from '@th-common/interfaces/video/video-request';
import { dvrApiRoot } from '@th-common/store/dvr-api';
import { ConvertCasesUtils } from '@th-common/utils/convert-cases';

interface IDvrVideoDownloadParams {
  dvrConnectionAddress: string;
  videoDownloadId: number | string;
}

export const dvrApi = dvrApiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInfo: builder.query<IDvrInfo, string>({
      query: (dvrConnectionAddress: string) => `${dvrConnectionAddress}/api/info`,
      extraOptions: {
        ignoreBaseUrl: true,
        skipErrorNotification: true,
      },
    }),
    getTracks: builder.query<IBaseVideoDownloadTrack[], string>({
      query: (dvrConnectionAddress: string) => `${dvrConnectionAddress}/api/tracks`,
      transformResponse: (response: IDvrVideoTrack[]) => {
        return response.map((track) => ConvertCasesUtils.convertPascalCaseToCamelCase<IDvrVideoTrack, IBaseVideoDownloadTrack>(track));
      },
      extraOptions: {
        ignoreBaseUrl: true,
        skipErrorNotification: true,
      },
    }),
    getVideoDownload: builder.query<IVideoFile, IDvrVideoDownloadParams>({
      query: ({
        dvrConnectionAddress,
        videoDownloadId,
      }) => `${dvrConnectionAddress}/api/video-download/${videoDownloadId}/index.json`,
      transformResponse: (response: IDvrVideoFile) => ConvertCasesUtils.convertPascalCaseToCamelCase<IDvrVideoFile, IVideoFile>(response),
      extraOptions: {
        ignoreBaseUrl: true,
        skipErrorNotification: true,
      },
    }),
  }),
});
