export namespace ConvertCasesUtils {
  // Helper function to convert Pascal case to camel case with acronym handling using regex
  export const pascalCaseToCamelCase = (s: string): string => {
    if (!s || s.length === 0) {
      return s;
    }

    // First, make the first character lowercase
    const result = s.charAt(0).toLowerCase() + s.slice(1);

    // Find sequences of uppercase letters (acronyms)
    // Look for 2+ uppercase letters followed by a lowercase letter or end of string
    return result.replace(/([A-Z]{2,})([a-z0-9]|$)/g, (match, acronym, nextChar) => {
      // Convert all letters except the last one to lowercase
      // If nextChar is a lowercase letter, keep the last uppercase letter uppercase
      // If nextChar is empty (end of string), make all lowercase
      if (nextChar) {
        return acronym.slice(0, -1).toLowerCase() + acronym.slice(-1) + nextChar;
      }
      return acronym.toLowerCase();
    });
  };

  // Convert object keys from PascalCase to camelCase recursively with generic types
  export const convertPascalCaseToCamelCase = <
    T extends Record<string, any> | any[] | null | undefined,
    R = T extends (infer U)[]
      ? U extends Record<string, any>
        ? Record<string, any>[]
        : T
      : T extends Record<string, any>
        ? Record<string, any>
        : T,
  >(obj: T): R => {
    if (obj === null || obj === undefined || typeof obj !== 'object') {
      return obj as unknown as R;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => convertPascalCaseToCamelCase(item)) as unknown as R;
    }

    return Object.keys(obj).reduce((result, key) => {
      const camelKey = pascalCaseToCamelCase(key);
      result[camelKey] = convertPascalCaseToCamelCase(obj[key]);
      return result;
    }, {} as Record<string, any>) as R;
  };

  // Convert snake_case to Title Case
  export const snakeToTitleCase = (snakeStr: string): string => {
    return snakeStr
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
}
