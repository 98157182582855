export enum TAvSourceType {
  Camera = 'Camera',
  IPCamera = 'IPCamera',
  Audio = 'Audio',
  IPAudio = 'IPAudio',
}

export const isAvSourceCamera = (avSourceType: TAvSourceType | null): boolean => {
  return avSourceType === TAvSourceType.Camera || avSourceType === TAvSourceType.IPCamera;
};

export const isAvSourceAudio = (avSourceType: TAvSourceType | null): boolean => {
  return avSourceType === TAvSourceType.Audio || avSourceType === TAvSourceType.IPAudio;
};
