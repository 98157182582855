import { IVideoPlaybackState, TPlayerMode } from '../video-playback-state.interface';

import { VideoPlaybackUtils } from './video-player-utils';

export namespace VideoTrackUtils {
  export const switchToNextTrackOrStopPlaying = (
    state: IVideoPlaybackState,
  ): void => {
    if (state.playerMode === TPlayerMode.Download) {
      if (state.currentTrackIndex === null || state.currentTrackIndex === state.tracks.length - 1) {
        state.playingForward = false;
        return;
      }

      const newTrackIndex = state.currentTrackIndex + 1;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: newTrackIndex,
        currentTimelineValue: state.tracks[newTrackIndex].startTimelineValue,
      });
      Object.assign(state, stateChanges);
    } else {
      if (state.videoDownloadTrackIndex === null || state.videoDownloadTrackIndex === state.videoDownloadTracks.length - 1) {
        state.playingForward = false;
        return;
      }

      state.videoDownloadTrackIndex += 1;
    }
  };

  export const switchToPreviousTrackOrStopPlaying = (
    state: IVideoPlaybackState,
  ): void => {
    if (state.playerMode === TPlayerMode.Download) {
      if (state.currentTrackIndex === null || state.currentTrackIndex === 0) {
        state.playingBackward = false;
        return;
      }

      const newTrackIndex = state.currentTrackIndex - 1;
      const stateChanges = VideoPlaybackUtils.returnStateAfterTrackChange({
        ...state,
        currentTrackIndex: newTrackIndex,
        currentTimelineValue: state.tracks[newTrackIndex].endTimelineValue!,
      });
      Object.assign(state, stateChanges);
    } else {
      if (state.videoDownloadTrackIndex === null || state.videoDownloadTrackIndex === 0) {
        state.playingBackward = false;
        return;
      }

      state.videoDownloadTrackIndex -= 1;
    }
  };
}
