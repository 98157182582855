import { IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { ICameraHlsData, IVideoDownloadTrack, IVideoHlsTrack } from '@th-common/interfaces/video/video-request';
import dayjs, { Dayjs } from 'dayjs';

import { IVideoPlaybackState, TPlayerMode } from '../video-playback-state.interface';

export namespace VideoPlaybackUtils {
  export const getCurrentTimelineDayjs = (videoRequestStartDayjs: Dayjs, currentTimelineValue: number): Dayjs => {
    return videoRequestStartDayjs.add(currentTimelineValue, 'ms');
  };

  export const dateToTimelineValue = (videoRequestStartDayjs: Dayjs, dateTime: string): number => {
    return dayjs.parseZone(dateTime).diff(videoRequestStartDayjs, 'ms');
  };

  export const roundPlayerCurrentTime = (playerCurrentTime: number): number => {
    return +(playerCurrentTime.toFixed(6));
  };

  export const roundTimelineValue = (timelineValue: number): number => {
    return +(timelineValue.toFixed(3));
  };

  export const playerTimeToTimelineValue = (playerTime: number): number => {
    return roundTimelineValue(playerTime) * 1000;
  };

  export const isDeviceConnectionPlayerMode = (playerMode: TPlayerMode): boolean => {
    return playerMode === TPlayerMode.Online || playerMode === TPlayerMode.Offline;
  };

  export const getTrackIndexByTimelineValue = (
    currentTimelineValue: number,
    tracks: Array<IVideoDownloadTrack | IVideoHlsTrack>,
    currentTrackIndex: number | null,
  ): number => {
    if (currentTrackIndex === null || (
      currentTimelineValue < tracks[currentTrackIndex].startTimelineValue
            || currentTimelineValue > tracks[currentTrackIndex].endTimelineValue)
    ) {
      return tracks.findIndex((track) => {
        return currentTimelineValue >= track.startTimelineValue && currentTimelineValue <= track.endTimelineValue;
      });
    }

    return currentTrackIndex;
  };

  export const getTrackIndexByDay = (
    dateDayjs: Dayjs,
    tracks: Array<IVideoDownloadTrack | IVideoHlsTrack>,
  ): number => {
    return tracks.findIndex((track) => {
      const trackStartDateDayjs = dayjs.parseZone(track.start);
      const trackEndDateDayjs = dayjs.parseZone(track.end);
      return dateDayjs.isBetween(trackStartDateDayjs, trackEndDateDayjs, 'day')
      || trackStartDateDayjs.isSame(dateDayjs, 'day')
      || trackEndDateDayjs.isSame(dateDayjs, 'day');
    });
  };

  export const getTimelineValueByPlayerTime = (
    playerTimeInMs: number,
    masterPlayerStartTimeline: number,
  ): number => {
    return roundTimelineValue(masterPlayerStartTimeline + playerTimeInMs);
  };

  export const getMinTimelineValueByTrack = (
    cameraHlsDataByTrack: Record<number, ICameraHlsData>[],
    currentTrackIndex: number,
    tracks: IVideoHlsTrack[],
  ): number => {
    const startTimelineValue = tracks[currentTrackIndex].startTimelineValue;
    const cameraHlsDataValues = Object.values(cameraHlsDataByTrack[currentTrackIndex]);

    return cameraHlsDataValues.reduce((acc, camera) => {
      if (camera.startTimelineValue < startTimelineValue) {
        return 0;
      }

      return Math.min(acc, camera.startTimelineValue);
    }, cameraHlsDataValues[0].startTimelineValue);
  };

  export const getMaxFpsByTrack = (
    cameraHlsDataByTrack: Record<number, ICameraHlsData>[],
    currentTrackIndex: number,
    cameras: IVideoSourceInfo[],
  ): number => {
    const visibleCameraNumbers = cameras.filter((camera) => !camera.isPlayerHide).map((camera) => camera.number);
    return Math.max(
      ...Object.values(cameraHlsDataByTrack[currentTrackIndex])
        .filter((camera) => visibleCameraNumbers.includes(camera.number))
        .map((camera) => camera.fps),
    );
  };

  export const getMasterPlayerNumber = (
    state: IVideoPlaybackState,
  ): number => {
    if (state.currentTrackIndex === null) {
      return -1;
    }

    const currentCameraHlsData = state.cameraHlsDataByTrack[state.currentTrackIndex];
    const cameraHlsDataByTrackValues = Object.values(currentCameraHlsData);

    let masterPlayerMaxFps = -1;
    let masterPlayerNumber = -1;

    let minStartPlayerNumber = cameraHlsDataByTrackValues[0].number;
    let maxEndPlayerNumber = cameraHlsDataByTrackValues[0].number;

    let ignoreMasterPlayer = false;

    const availableCameraNumbers = state.cameras.filter((camera) => !camera.isPlayerHide).map((camera) => camera.number);

    if (state.masterPlayerNumber !== -1) {
      const reachedMasterPlayerStart = state.currentTimelineValue === currentCameraHlsData[state.masterPlayerNumber].startTimelineValue;
      const reachedMasterPlayerEnd = state.currentTimelineValue === currentCameraHlsData[state.masterPlayerNumber].endTimelineValue;

      if (reachedMasterPlayerStart || reachedMasterPlayerEnd) {
        ignoreMasterPlayer = true;
      }
    }

    cameraHlsDataByTrackValues.forEach((cameraData) => {
      if (
        availableCameraNumbers.includes(cameraData.number)
        && !state.cameraNumbersBuffering.includes(cameraData.number)
      ) {
        if (cameraData.startTimelineValue <= currentCameraHlsData[minStartPlayerNumber].startTimelineValue) {
          minStartPlayerNumber = cameraData.number;
        }

        if (cameraData.endTimelineValue >= currentCameraHlsData[maxEndPlayerNumber].endTimelineValue) {
          maxEndPlayerNumber = cameraData.number;
        }

        if (ignoreMasterPlayer && cameraData.number === state.masterPlayerNumber) {
          return;
        }

        if (
          state.currentTimelineValue >= cameraData.startTimelineValue && state.currentTimelineValue <= cameraData.endTimelineValue
          && cameraData.fps > masterPlayerMaxFps
        ) {
          masterPlayerNumber = cameraData.number;
          masterPlayerMaxFps = cameraData.fps;
        }
      }
    });

    if (state.currentTimelineValue < state.cameraHlsDataByTrack[state.currentTrackIndex][minStartPlayerNumber].startTimelineValue) {
      return minStartPlayerNumber;
    }

    if (state.currentTimelineValue > state.cameraHlsDataByTrack[state.currentTrackIndex][maxEndPlayerNumber].endTimelineValue) {
      return maxEndPlayerNumber;
    }

    return masterPlayerNumber;
  };

  export const updateCameraNumbersCanPlay = (state: IVideoPlaybackState): Pick<IVideoPlaybackState, 'cameraNumbersCanPlay'> => {
    if (state.currentTrackIndex === null || state.currentTrackIndex === -1) {
      return {
        cameraNumbersCanPlay: [],
      };
    }

    const cameraHlsDataByTrackValues = Object.values(state.cameraHlsDataByTrack[state.currentTrackIndex]);
    if (state.cameraNumbersCanPlay.length === cameraHlsDataByTrackValues.length) {
      return {
        cameraNumbersCanPlay: state.cameraNumbersCanPlay,
      };
    }

    return {
      cameraNumbersCanPlay: cameraHlsDataByTrackValues
        .filter((cameraData) => state.currentTimelineValue >= cameraData.startTimelineValue)
        .map((cameraData) => cameraData.number),
    };
  };

  export const updateTimelineValue = (state: IVideoPlaybackState): Pick<IVideoPlaybackState, 'cameraNumbersCanPlay' | 'currentTimelineValue'> => {
    return {
      currentTimelineValue: roundTimelineValue(state.currentTimelineValue),
      ...updateCameraNumbersCanPlay(state),
    };
  };

  export const returnStateAfterTrackChange = (
    state: IVideoPlaybackState,
  ): Partial<IVideoPlaybackState> => {
    const currentTrackIndex = state.currentTrackIndex;

    if (currentTrackIndex === null || currentTrackIndex === -1) {
      return {
        playingForward: false,
        playingBackward: false,
        currentTrackIndex: null,
        masterPlayerNumber: -1,
        playerCurrentTimeRelated: 0,
        ...updateTimelineValue(state),
      };
    }

    const masterPlayerNumber = getMasterPlayerNumber(state);

    if (masterPlayerNumber === -1) {
      return {
        playingForward: false,
        playingBackward: false,
        masterPlayerNumber: -1,
        playerCurrentTimeRelated: 0,
        ...updateTimelineValue(state),
      };
    }

    const masterPlayerStartTimeline = state.cameraHlsDataByTrack[currentTrackIndex][masterPlayerNumber].startTimelineValue;
    const masterPlayerEndTimeline = state.cameraHlsDataByTrack[currentTrackIndex][masterPlayerNumber].endTimelineValue;

    let timelineValue = state.currentTimelineValue;

    if (timelineValue < masterPlayerStartTimeline) {
      timelineValue = masterPlayerStartTimeline;
    }

    if (timelineValue > masterPlayerEndTimeline) {
      timelineValue = masterPlayerEndTimeline;
    }

    const {
      currentTimelineValue,
      cameraNumbersCanPlay,
    } = updateTimelineValue({
      ...state,
      currentTimelineValue: timelineValue,
    });

    const timelineRelatedToTrack = currentTimelineValue - state.tracks[currentTrackIndex].startTimelineValue;
    const playerCurrentTimeRelated = VideoPlaybackUtils.roundTimelineValue(timelineRelatedToTrack) / 1000;

    return {
      currentTrackIndex: state.currentTrackIndex,
      masterPlayerNumber,
      playerCurrentTimeRelated: +playerCurrentTimeRelated.toFixed(6),
      currentTimelineValue,
      cameraNumbersCanPlay,
    };
  };
}
